import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=1c583523&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=ts&"
export * from "./Card.vue?vue&type=script&lang=ts&"
import style0 from "./Card.vue?vue&type=style&index=0&id=1c583523&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c583523",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImageOrFallback: require('/var/www/packages/theme/components/atoms/ImageOrFallback.vue').default,MoleculesCmsUrlWrapper: require('/var/www/packages/theme/components/molecules/CmsUrlWrapper.vue').default})
