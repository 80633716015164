















import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';

export type CardData = {
  id: number;
  number_of_columns: number;
  media?: {
    media_path: string;
    related_url: string;
    url_title: string;
    additional_text: string;
  }[];
  urls?: {
    id: number;
    title: string;
    url: string;
    target: string;
    media_path: string;
    overlay_text: string;
  }[];
};

export default defineComponent({
  name: 'ServiceSectionCard',
  props: {
    data: {
      type: Object as PropType<CardData>,
      required: true,
      default: () => ({ id: 0, number_of_columns: 0, media: [] })
    }
  },
  setup(props) {
    const cardData = computed(() => {
      const mediaItem = props.data.urls?.[0] ?? {
        media_path: '',
        url: '',
        title: '',
        target: ''
      };

      return {
        mediaPath: mediaItem.media_path ?? '',
        url: mediaItem.url ?? '#',
        target: mediaItem.target || '_self',
        altText: mediaItem.title ?? '',
        text: mediaItem.title ?? ''
      };
    });

    return {
      cardData
    };
  }
});
